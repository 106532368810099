<template>
    <Layout>
        <PageHeader
            :items="items"
            :title="title"
            :link="`/${this.$route.params.slug}/webinars`"
        />

        <div class="pageContent backgroundZircon">
            <VideoArticle
                v-if="webinar && webinar.videos && webinar.videos.length"
                :title="webinar.theme"
                :date="date"
                :videoThumb="webinar.videos.length && webinar.videos[0]"
                :video="webinar.videos.length && webinar.videos[0]"
                :author="webinar.curator"
            />
        </div>
    </Layout>
</template>

<script>
import Layout from "@/views/layouts/Layout";
import PageHeader from "@/views/partials/pageHeader/pageHeader";
import ButtonVideoProblem from "@/views/components/ButtonVideoProblem/ButtonVideoProblem";
import VideoArticle from "@/views/components/VideoArticle/VideoArticle";

import { getDateFromDatetime } from "../../../../helpers/dateFormat";

export default {
    name: "webinarsRecording",
    components: {
        Layout,
        PageHeader,
        ButtonVideoProblem,
        VideoArticle,
    },

    async beforeMount() {
        await this.setWebinar();
        this.date = getDateFromDatetime(this.webinar && this.webinar.datetime);
    },

    data() {
        return {
            title: "Запись вебинара",
            webinar: {},
            date: "",
            items: [
                {
                    text: "Вебинары",
                    to: `/${this.$route.params.slug}/webinars`,
                },
                {
                    text: "Запись вебинара",
                    active: true,
                },
            ],
        };
    },

    mounted() {
        if (this.webinar.status !== "isWatched") {
            setTimeout(() => {
                this.setSelectedWebinar();
            }, 30000);
        }
    },

    methods: {
        setSelectedWebinar: async function() {
            const itemId = this.webinar._id;
            await this.axios.get(
                `/programs/${this.$route.params.slug}/webinars/${itemId}/isWatched`
            );
        },

        async setWebinar() {
            const getWebinarResult = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/webinars/" + this.$route.params.webinar_id
            );

            if (
                getWebinarResult &&
                getWebinarResult.data &&
                getWebinarResult.data.result
            ) {
                this.webinar = getWebinarResult.data.webinar;
            } else {
                console.error(
                    "Ошибка при поиске вебинара: " + getWebinarResult.data.error
                );

                if (getWebinarResult.data && getWebinarResult.data.error) {
                    await this.$store.dispatch("setAlert", {
                        alert: {
                            isActive: true,
                            type: "error",
                            text: getWebinarResult.data.error,
                        },
                    });
                }

                await this.$router.push("/" + this.$route.params.slug + "/webinars");
            }
        }
    },
};
</script>

<style lang="scss">
@import "./webinarsRecording.scss";
</style>
